// DynamicTextareaFields.tsx
import React, { useState } from 'react';
import { Typography, TextareaAutosize, Button, Select, MenuItem, Box, Dialog, DialogTitle, DialogContent } from '@mui/material';
import CustomButton from './CustomButton';
import { useDispatch, useSelector } from 'react-redux';
import { handleGeneratePdf, HandleMakeScore } from './apis';
import CONSTANTS from '../constants/constants';
import {useNavigate } from 'react-router-dom';


interface DynamicTextareaFieldsProps {
  fields: string[];
  selectedItem: string;
  selectedOption: string;
  formik: any; // Update the type based on your formik configuration
  onSaveClick: any;
  onTextareaChange: (field: string, value: string) => void;
  previewData:any;
}

const priorityValues = ['High', 'Medium', 'Low', 'Informative','Critical'];

const DynamicTextareaFields: React.FC<DynamicTextareaFieldsProps> = ({
  fields,
  selectedItem,
  selectedOption,
  formik,
  onSaveClick,
  onTextareaChange,
  previewData
 
}) => {
  const [isPreviewDialogOpen, setPreviewDialogOpen] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isScoreDone } = useSelector((state: any) => ({ isScoreDone: state.isScoreDone }));

  const handlePreviewOpen = () => {
    setPreviewDialogOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewDialogOpen(false);
  };


  const dataToPreview = {
    // customer_details: { 
    //   ...previewData.customer_details
    // },
    // id: { ...previewData.id },
    data: { ...formik.values.data },
    score: { ...previewData.score }
  }


  return (
    <Box>
      <>
      {formik.values.data[selectedItem] &&
      formik.values.data[selectedItem][selectedOption] &&
      fields.map((field) => {
        return (
          <div key={field} style={{ marginTop: '1rem' }}>
            <Typography textTransform={"capitalize"} fontSize="1rem" variant="h6">{field.replace(/_/g, ' ')}</Typography>
            {field === 'priority' ? (
              <Select
                value={formik.values.data[selectedItem]?.[selectedOption]?.[field] || ''}
                onChange={(e) => {
                  const updatedValue = e.target.value as string;
                  onTextareaChange(field, updatedValue);
                }}
                style={{fontFamily:"Barlow",width: '100%' }}
              >
                {priorityValues.map((priority) => (
                  <MenuItem key={priority} value={priority}>
                    {priority}
                  </MenuItem>
                ))}
              </Select>
            ) : field === 'reference' ? (
              <a
                style={{fontFamily:"Barlow"}}
                href={formik.values.data[selectedItem][selectedOption][field]}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formik.values.data[selectedItem][selectedOption][field]}
              </a>
            ) : (
              <TextareaAutosize
                minRows={3}
                placeholder={`Enter ${field.replace(/_/g, ' ')}`}
                style={{ fontFamily:"Barlow",width: '100%' }}
                value={formik.values.data[selectedItem]?.[selectedOption]?.[field] || ''}
                onChange={(e:React.ChangeEvent<HTMLTextAreaElement>) => {
                  const updatedValue = e.target.value;
                  onTextareaChange(field, updatedValue);
                }}
                onResize={(e:any) => {}}
                onResizeCapture={(e:any) => {}}
              />
            )}
          </div>
        );
      })}
       <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {/* <CustomButton onClick={onSaveClick} label="Save" /> */}
          {selectedItem === "others" && <Button 
            sx={{textTransform:"none",fontFamily:"Barlow"}}
            variant="contained" type="submit">Submit</Button>}
        <Box sx={{ mt:2,display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        
          <CustomButton onClick={handlePreviewOpen} label="Template Preview" />
          <CustomButton onClick={() => HandleMakeScore(dispatch,previewData['id']['version_id'])} label="Score" />
          
          <Button 
            sx={{textTransform:"none",fontFamily:"Barlow"}}
            disabled={!isScoreDone}
            onClick={() => handleGeneratePdf(dispatch,previewData['id']['version_id'])}
            variant="contained" type="button">Generate PDF</Button>
        </Box>
      </Box>
      <Dialog open={isPreviewDialogOpen} onClose={handlePreviewClose}>
        <DialogTitle  sx={{fontFamily:"Barlow"}}>Template Preview</DialogTitle>
        <DialogContent>
          {/* Display saved data in the Dialog */}
     
          <pre  style={{fontFamily:"Barlow"}}>{JSON.stringify({...dataToPreview}, null, 2)}</pre>
        </DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '1rem' }}>
          <Button 
            sx={{textTransform:"none",fontFamily:"Barlow"}}
            onClick={handlePreviewClose} 
            variant="contained"
            color="primary">
            Close
          </Button>
        </Box>
      </Dialog>
      </>
    </Box>
   
  );
};

export default DynamicTextareaFields;
