export const TEMPLATE_UPLOAD = '/dms/v1/documents?documentTypeId=1080891642721890304';
export const TEMPLATE_DOWNLOAD = '/dms/v1/documents';
export const DELETE_DOCUMENT = '/dms/v1/documents?id='
export const GENERATE_OTP = '';
export const VERIFY_OTP = '';
export const KEYCLOAK_TOKEN = 'auth/realms/techsophy-platform/protocol/openid-connect/token';



export const SAVE_USER_DATA = '/api/saveUserData'
export const GET_CUSTOMER_DETAILS = '/api/getCustomerDetails'
export const GET_VERSION_BY_ID = '/api/getVersionBasedOnId'
export const GET_DATA_BY_ID = '/api/getDataById'
export const UPDATE_TEMPLATE = '/api/saveData'
export const DELETE_TEMPLATE = '/api/deleteTemplate/'
export const PDF_GENERATION = '/api/generatePdf'
export const PDF_PREVIEW = '/dms/v1/documents/download?id=';
export const SEND_EMAIL = "/api/sendEmail";
export const MAKE_SCORE ='/api/makeScore';
export const RUN_TOOLS = '/api/runTheTools';
