import React from "react";
import { Route, Routes} from "react-router-dom";
import CustomerDetails from "../../screen/Customer";
import ExistingCustomer from "../../screen/ExistingCustomer";
import HomePage from "../../screen/HomePage";
const Navigator = () => {

  return (
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/new-customer" element={<CustomerDetails/>} />
      <Route path="/existing-customer" element={<ExistingCustomer/>} />
      <Route path="/existing-customer/version-data" element={<ExistingCustomer/>} />
    </Routes>
  );
};

export default Navigator;
