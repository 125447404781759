import React from 'react';
import { makeStyles, Box} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { LinearProgress, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles(() => ({
    preLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft:2
        // height: '100vh', // Adjust the height to fit your layout
    },
}));

const PreLoader = () => {
    const classes = useStyles();

    const { isLoadingTools } = useSelector((state: any) => ({ 
        isLoadingTools: state.isLoadingTools}));


    return (
        // Conditional rendering based on isLoadingTools
        isLoadingTools ? (
            <Box className={classes.preLoader}>
                <CircularProgress size={25}/>
                {/* <p>loading...</p>
                <LinearProgress variant='indeterminate' /> */}

            </Box>
        ) : null
    );
};

export default PreLoader;
