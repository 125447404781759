import { Button } from "@mui/material";

interface CustomButtonProps {
    onClick: () => void;
    label: string;
  }
  
  const CustomButton: React.FC<CustomButtonProps> = ({ onClick, label }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={onClick}
        sx={{textTransform:"none",fontFamily:"Barlow", color: 'white', marginRight: '1rem' }}
      >
        {label}
      </Button>
    );
  };


  export default CustomButton