import React from 'react';
import { Modal, Typography, Box, Button } from '@mui/material';
import { useSelector } from 'react-redux';

interface CustomPopupProps {
  open: boolean;
  onClose: () => void;
  customerData: {
    name: string;
    url: string;
    date: string;
    version: number;
  };
  onSave: () => void; // Replace with your onSave function
  onRun: () => void; // Replace with your onRun function
}

const CustomPopup: React.FC<CustomPopupProps> = ({
  open,
  onClose,
  customerData,
  onSave,
  onRun,
}) => {

  const { isToolsRun } = useSelector((state: any) => ({ 
    isToolsRun: state.isToolsRun}));
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          boxShadow: 24,
          p: 2,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography sx={{fontFamily:"Barlow"}} variant="h5">Customer Details</Typography>
        <Typography  sx={{fontFamily:"Barlow"}}>Name: {customerData.name}</Typography>
        <Typography  sx={{fontFamily:"Barlow"}}>URL: {customerData.url}</Typography>
        <Typography  sx={{fontFamily:"Barlow"}}>Date: {customerData.date}</Typography>
        <Typography  sx={{fontFamily:"Barlow"}}>Version: {customerData.version}</Typography>

        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
          <Button 
            sx={{textTransform:"none",fontFamily:"Barlow"}}
            disabled={!isToolsRun.toolsButton}
            variant="contained" color="primary" onClick={onSave}>
            Save
          </Button>
          <Button 
            sx={{textTransform:"none",fontFamily:"Barlow"}}
            disabled={isToolsRun.toolsButton}
            variant="contained" color="primary" onClick={onRun}>
            Run
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CustomPopup;
