import React, { useEffect, useState } from "react";
import { Spinner } from "../../common/Spinner";
import Notification from "../../common/notification";
import { useDispatch, useSelector } from "react-redux";
import { pushNotification } from "../../../redux/actions";
import { Alert, Box, Snackbar } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { request } from "../../../services/request";
import CONSTANTS from "../../../constants/constants";
import { styled } from '@mui/material/styles';
import { useLocation } from "react-router-dom";
import SidebarLayout from "../../../screen/Sidebar";
import PreLoader from "../../common/preLoader";

const StyledRoot = styled('div')({
  display: 'flex',
  flexDirection:"column",
  minHeight: '100%',
  overflow: 'hidden',

});

const Wrapper = (props) => {


  const dispatch = useDispatch();
  const { notification } = useSelector((state: any) => ({
    notification: state.notification
  }));

  const closeNotification = () => {
    dispatch(
      pushNotification({
        ...notification,
        isOpen: false,
      })
    );
  };

  
  return window.location.href.indexOf("signup") <= -1 ?
  (
     <StyledRoot> 
        <div>{props.children}</div>
        <Spinner />
      
      {/* <Footer /> */}
      <div>
      <Snackbar
          open={notification.isOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={closeNotification}>
          <Alert onClose={closeNotification} severity={notification.type} variant="filled">
              {notification.message}
          </Alert>
        </Snackbar>
      </div>
     
    </StyledRoot>

  ) : (
   
    <div>
      <Notification
        isOpen={notification.isOpen}
        type={notification.type}
        message={notification.message}
        handleClose={closeNotification}
      />
     
      {props.children}
      <Spinner />
    </div>
  );
};

export default Wrapper;
