import { useDispatch } from "react-redux";
import CONSTANTS from "../constants/constants";
import { DELETE_TEMPLATE, GET_DATA_BY_ID, MAKE_SCORE, PDF_GENERATION, PDF_PREVIEW, RUN_TOOLS, SAVE_USER_DATA, SEND_EMAIL, UPDATE_TEMPLATE } from "../constants/endpoints";
import { pushNotification, setIsExistingCustomer, setIsLoadingTools, setIsScoreDone, setIsToolsRun, setLoader, setShowDisplayVersions, setVersionData, setVersionLoader } from "../redux/actions";
import { request } from "../services/request";
import initialData from './initial.json'

export const onSubmitUserData = async (dispatch:any,formikValues: any) => {

  const SAVE_USER_URL = `${process.env.REACT_APP_ERA_GATEWAY_URL}${SAVE_USER_DATA}`;
  const GET_DATA_URL = `${process.env.REACT_APP_ERA_GATEWAY_URL}${GET_DATA_BY_ID}`;

  const userData = {
    date: formikValues['date'],
    name: formikValues['name'],
    url: formikValues['url'],
    version: formikValues['version'],
    customer_id: formikValues['id']['customer_id'],
  };

  try {
    dispatch(setLoader(true))
    const res: any = await request.post(SAVE_USER_URL, userData);
    dispatch(setLoader(false))

    if (res?.success) {
      const version_id = res.data.version_id;
      const data: any = await request.post(GET_DATA_URL, { id: version_id });

      dispatch(setVersionData(data));
      dispatch(setVersionLoader(data["id"]["version_id"]))
      console.log("version ",data["id"]["version_id"])
      dispatch(setIsToolsRun({
        toolsButton:false,
        menus:true
      }));

      dispatch(
        pushNotification({
          isOpen: true,
          message: res?.message,
          type: CONSTANTS.SUCCESS,
        })
      );
    } else {
      dispatch(
        pushNotification({
          isOpen: true,
          message: res?.error,
          type: CONSTANTS.ERROR,
        })
      );
    }
  } catch (error) {
    dispatch(
      pushNotification({
        isOpen: true,
        message: error,
        type: CONSTANTS.ERROR,
      })
    );
  }
};

export const onSubmitFormData =async (dispatch,formik,versionData) => {
  const userData = {
    date: versionData.customer_details["date"],
    name: versionData.customer_details["name"],
    url: versionData.customer_details["url"],
    version: versionData.customer_details["version"],
    customer_id: versionData['id']['customer_id'],
  };

  // Update the saved data state
  const formData = {
    customer_details: { ...userData },
    id: { ...versionData.id },
    data: { ...formik.values.data },
    score: { ...versionData.score },
  };    

  const UPDATE_DATA_URL = `${process.env.REACT_APP_ERA_GATEWAY_URL}${UPDATE_TEMPLATE}` 

  dispatch(setLoader(true))
  const res:any = await request.post(UPDATE_DATA_URL,{
    "form_data":[formData],"version_id":versionData["id"]["version_id"]
  })

  dispatch(setLoader(false))


  if(res?.success){
    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.message,
        type: CONSTANTS.SUCCESS,
      })
    );
  }else{
    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.error,
        type: CONSTANTS.ERROR,
      })
    );
  }
}


export const handleRunTools = async(dispatch,data) => {
  // console.log("versionId in api ",data["_id"])
  const RUN_TOOLS_URL = `${process.env.REACT_APP_ERA_AGENT_API_URL}${RUN_TOOLS}` 

  try{
      dispatch(setIsLoadingTools(true))
      

      const res:any = await request.post(RUN_TOOLS_URL,{
        "version_id" :data["_id"]
      })

      dispatch(setIsLoadingTools(false))

      if(res?.success){
        dispatch(setIsToolsRun({
            toolsButton:true,
            menus:false
          }));
        getData(dispatch,data["_id"])

        dispatch(
          pushNotification({
            isOpen: true,
            message: res?.message,
            type: CONSTANTS.SUCCESS,
          })
        );
      }else{
        dispatch(
          pushNotification({
            isOpen: true,
            message: res?.error,
            type: CONSTANTS.ERROR,
          })
        );
      }
    } catch (error) {
      console.error('Error running tools:', error);
      dispatch(setIsLoadingTools(false));
  }
};


export const handleDeleteVersion =async (dispatch,versionId,handleGetVersions) => {

  const DELETE_VERSION_TEMPLATE = `${process.env.REACT_APP_ERA_GATEWAY_URL}${DELETE_TEMPLATE}${versionId}`;

  const res:any = await request.delete(DELETE_VERSION_TEMPLATE);


  if (res?.success) {
    handleGetVersions()
    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.message,
        type: CONSTANTS.SUCCESS,
      })
    );
  } else {
    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.error,
        type: CONSTANTS.ERROR,
      })
    );
  }
  
}


export const handleGeneratePdf = async (dispatch,versionId) => {

  const GENERATE_PDF_URL = `${process.env.REACT_APP_ERA_GATEWAY_URL}${PDF_GENERATION}`;
  dispatch(setLoader(true))

  const res:any = await request.post(GENERATE_PDF_URL,{
    version_id:versionId
  });
  dispatch(setLoader(false))


  if (res?.success) {

    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.message,
        type: CONSTANTS.SUCCESS,
      })
    );
  } else {
    dispatch(
      pushNotification({
        isOpen: true,
        message: res?.error,
        type: CONSTANTS.ERROR,
      })
    );
  }
  
}

// Function to get access token
const getAccessToken = async () => {  
  try {
    const requestBody = new URLSearchParams({
      username: `${process.env.REACT_APP_ACCESS_TOKEN_USERNAME}`,
      password: `${process.env.REACT_APP_ACCESS_TOKEN_PASSWORD}`,
      client_id: `${process.env.REACT_APP_ACCESS_TOKEN_CLIENT_ID}`,
      grant_type: `${process.env.REACT_APP_ACCESS_TOKEN_GRANT_TYPE}`,
      client_secret: `${process.env.REACT_APP_ACCESS_TOKEN_CLIENT_SECRET}`,
    });
    console.log(requestBody)

    const response = await fetch(`${process.env.REACT_APP_ACCESS_TOKEN_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: requestBody,
    });

    if (response.ok) {
      const data = await response.json();
      return data.access_token || null;
    } else {
      const errorData = await response.json(); // Parse error response
      console.error('Error fetching access token:', response.status, errorData);
      return null;
    }
  } catch (error) {
    console.error('Error fetching access token:', error);
    return null;
  }
};



  // Function to download the PDF
  export const PDFPreview = async (dispatch,pdfId) => {
    console.log(pdfId)

    const accessToken = await getAccessToken();
    if (!accessToken) {
      console.error('Error: Access token not obtained');
      return { success: false, message: 'Access token not obtained' };
    }
    
    if (pdfId) {
      dispatch(setLoader(true))

      const url = `${process.env.REACT_APP_API_GATEWAY_URL}${PDF_PREVIEW}${pdfId}`;
      try {
        const response = await fetch(url, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
  
        const blobFile = await response.blob();

        dispatch(setLoader(false))

  
        if (response.ok) {
          // Create a Blob URL for the PDF
          const pdfUrl = window.URL.createObjectURL(blobFile);
  
          // Open a new window or tab with the PDF content
          window.open(pdfUrl, '_blank');
  
          // Release the Blob URL
          window.URL.revokeObjectURL(pdfUrl);
  
          return { success: true };
        } else {
          // Handle non-successful response
          return { success: false, message: `Failed to open PDF. Status: ${response.status}` };
        }
      } catch (error) {
        // Handle network or other errors
        return { success: false, message: `Error: ${error}` };
      }
    } else {

        dispatch(
          pushNotification({
            isOpen: true,
            message: "PDF ID not found",
            type: CONSTANTS.ERROR,
          })
        );
    
    }
  };



export const handleSendEmail = async (dispatch,data,handleClose) =>{
  try {
    // Dummy API Call (Replace with your actual API endpoint)
    dispatch(setLoader(true))

    const response:any = await request.post(`${process.env.REACT_APP_ERA_GATEWAY_URL}${SEND_EMAIL}`, data)
    dispatch(setLoader(false))

    if (response?.success) {

      // Check if the response indicates success
     
        // Show alert for successful email sent
        handleClose();
          dispatch(
            pushNotification({
              isOpen: true,
              message: "Email sent successfully!",
              type: CONSTANTS.SUCCESS,
            })
          );
    }
      else {
        dispatch(
          pushNotification({
            isOpen: true,
            message: "Failed to send email. Please try again.",
            type: CONSTANTS.ERROR,
          })
        );   // Show an alert for failure
      }
  } catch (error) {
    dispatch(
      pushNotification({
        isOpen: true,
        message: "Failed to send email. Please try again.",
        type: CONSTANTS.ERROR,
      })
    );  
  }
}


const getData = async (dispatch,versionId) =>{
  const res:any = await request.post(`${process.env.REACT_APP_ERA_GATEWAY_URL}${GET_DATA_BY_ID}`,{
    id:versionId
  })
  dispatch(setVersionData(res))
}

export const HandleMakeScore = async (dispatch,versionId)=>{

  try {
      // Dummy API Call (Replace with your actual API endpoint)
      dispatch(setLoader(true))
      const response:any = await request.post(`${process.env.REACT_APP_ERA_GATEWAY_URL}${MAKE_SCORE}`, {
        version_id:versionId
      })
      dispatch(setLoader(false))

      if (response?.success) {

          dispatch(setIsScoreDone(true))
          getData(dispatch,versionId)
         
          
            dispatch(
              pushNotification({
                isOpen: true,
                // message: response.message,
                message:"Score updated successfully",
                type: CONSTANTS.SUCCESS,
              })
            );
      }
        else {
          dispatch(
            pushNotification({
              isOpen: true,
              message: response.error,
              type: CONSTANTS.ERROR,
            })
          );   // Show an alert for failure
        }
    } catch (error) {
      dispatch(
        pushNotification({
          isOpen: true,
          message: "Failed to generate score Please try again.",
          type: CONSTANTS.ERROR,
        })
      );  
    }


}



export const handleGoToExistingCustomer = (dispatch,navigate) =>{
  console.log("going to exisitng customer")
  dispatch(setIsExistingCustomer(true))
  dispatch(setVersionData(initialData))
  navigate('/existing-customer');
    dispatch(setIsToolsRun({
      toolsButton:true,
      menus:true
  }))
}

export  const handleGoToNewCustomer = (dispatch,navigate) =>{
  console.log("going to exisitng customer")
  dispatch(setIsExistingCustomer(false))
  dispatch(setVersionData(initialData))
  navigate('/new-customer');
}



export const handleVersionClick = async (dispatch,versionId) =>{

  const res:any = await request.post(`${process.env.REACT_APP_ERA_GATEWAY_URL}${GET_DATA_BY_ID}`,{
    id:versionId
  })
  dispatch(setVersionData(res))
  dispatch(setShowDisplayVersions(true));

}