// CustomerDetailsForm.tsx

import React from 'react';
import { TextField,Button, Typography, Box } from '@mui/material';

interface CustomerDetailsFormProps {
  fieldKeys: string[];
  formik: any;
}



const CustomerDetailsForm: React.FC<CustomerDetailsFormProps> = ({ fieldKeys, formik }) => {


  return (
  
    <>
      
      {fieldKeys.map((field) => {
      return (
      <>
        <TextField
          sx={{textTransform:"none",fontFamily:"Barlow",}}
          key={field}
          fullWidth
          id={field}
          name={field}
          label={field.charAt(0).toUpperCase() + field.slice(1)}
          variant="outlined"
          margin="normal"
          type="text"
          disabled={field === 'date' || field === "version"}
          value={formik.values[field]}
          onChange={formik.handleChange}
          error={formik.touched[field] && Boolean(formik.errors[field])}
          helperText={formik.touched[field] && formik.errors[field]}
        />
      
      </>
      )})}
      
    </>
  );
};

export default CustomerDetailsForm;
