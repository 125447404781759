import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const DeleteConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{fontFamily:"Barlow"}}>Confirm Delete</DialogTitle>
      <DialogContent  sx={{fontFamily:"Barlow"}}>
        Are you sure you want to delete this item?
      </DialogContent>
      <DialogActions>
        <Button  sx={{textTransform:"none",fontFamily:"Barlow"}} onClick={onClose}>Cancel</Button>
        <Button  sx={{textTransform:"none",fontFamily:"Barlow"}} onClick={onConfirm} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
