
import { PUSH_NOTIFICATION } from '../constants/actions';
import CONSTANTS from '../constants/constants';

// Action Type
export const LOADER = 'LOADER';
export const ACCESS_TOEKN = 'ACCESS_TOEKN';
export const VERSION_DATA = "VERSION_DATA";
export const IS_EXISTING_CUSTOMER = "IS_EXISTING_CUSTOMER";
export const IS_TOOLS_RUN = "IS_TOOLS_RUN"
export const IS_SCORE_CALCULATED = 'SCORE_CALCULATED'
export const SET_VERSION_LOADER ="SET_VERSION_LOADER"
export const SET_IS_LOADING_TOOLS = "SET_IS_LOADING_TOOLS"
export const SET_SHOW_VERSIONS = "SET_SHOW_VERSIONS"

const pathName = window.location.pathname


// Action
export const setLoader = (loading) => {
    return {
        type: LOADER,
        payload: loading,
    };
};

export const setAnonymousUser = (data) => {
    return {
        type: LOADER,
        payload: data,
    };
};

export const pushNotification = (data) => {
    return { type: PUSH_NOTIFICATION, payload: data };
};

export const setAccessToken = (token) => {
    return {
        type: ACCESS_TOEKN,
        payload: token
    }
}

export const setVersionData = (versionData) => {
    return {
        type: VERSION_DATA,
        payload: versionData
    }
}

export const setIsExistingCustomer = (isExistingCustomer) => {
    sessionStorage.setItem(CONSTANTS.IS_EXISTING_CUSTOMER,isExistingCustomer)
    return {
        type: IS_EXISTING_CUSTOMER,
        payload: isExistingCustomer
    }
}

export const setIsToolsRun = (isToolsRun) => {

    return {
        type: IS_TOOLS_RUN,
        payload: isToolsRun
    }
}


export const setIsScoreDone  = (isScoreDone) => {
    return {
        type: IS_SCORE_CALCULATED,
        payload: isScoreDone
    }
}

export const setVersionLoader = (versionLoader) => ({
    type: SET_VERSION_LOADER,
    payload: versionLoader,
  });

export const setIsLoadingTools = (isLoadingTools) => ({
    type: SET_IS_LOADING_TOOLS,
    payload: isLoadingTools,
});


export const setShowDisplayVersions = (showDisplayVersions) => ({
    type: SET_SHOW_VERSIONS,
    payload: showDisplayVersions,
});



switch (window.location.pathname) {
 
  case "/new-customer":
      setIsExistingCustomer(false)

      break;
  case "/existing-customer":

    setIsExistingCustomer(true)

      break;


  default:
      break;
}

