import React, { useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Container } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Sidebar from './Sidebar';
import { useSelector } from 'react-redux';
import { request } from '../services/request';
import DisplayVersions from './DisplayVersions';
import initialData from '../screen/initial.json'
import CONSTANTS from '../constants/constants';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
 
}));

const CustomerDetails = () => {
  const classes = useStyles();


  const {versionData } = useSelector((state: any) => ({versionData:initialData }));
  
  const isExistingCustomer = sessionStorage.getItem("isExistingCustomer") === "true"?true:false

  const selectItem = isExistingCustomer? "dns_and_domain":"customer_details"

  const [selectedMenuItem, setSelectedMenuItem] = useState(selectItem); // Default to the first menu item

  const handleMenuItemClick = (itemId) => {
    setSelectedMenuItem(itemId);
  };


  return (
    <div className={classes.root}>
      <Sidebar 
        data={versionData} 
        onItemClick={handleMenuItemClick} 
        selectedItem={selectedMenuItem} />
      <Container component="main" className={classes.content}>
        {selectedMenuItem !== null && (
          <DisplayVersions
            selectedItem={selectedMenuItem}
          />
        )}
      </Container>
    </div>
  );
};

export default CustomerDetails;

