import React from 'react';
import { Modal, Fade, Button, TextField, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { handleSendEmail } from './apis';
import { useDispatch } from 'react-redux';
const EmailForm = ({ isOpen, handleClose, emailData }) => {

    const dispatch = useDispatch()
    
    const validationSchema = Yup.object({
      to: Yup.string().transform((value, originalValue) => {
        // Allow multiple email addresses separated by commas
        const emails = originalValue.split(',').map((email) => email.trim());
        return emails.join(', ');
      }).email('Invalid email address').required('Required'),
      cc: Yup.string().transform((value, originalValue) => {
        // Allow multiple email addresses separated by commas
        const emails = originalValue.split(',').map((email) => email.trim());
        return emails.join(', ');
      }),
      // pdfS3Id: Yup.string(),
    });
  
    const formik = useFormik({
      initialValues: {
        to: '',
        cc: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        const body = { ...values,...emailData }
        handleSendEmail(dispatch,body,handleClose)
      },
    });
  
    return (
      <Modal
        open={isOpen}
        closeAfterTransition
        onClose={(event, reason) => {
          if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) {
            handleClose();
          }
        }}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        <Fade in={isOpen}>
          <Box
            sx={{
              backgroundColor: 'white',
              border: '2px solid #000',
              p: 5,
              mt: 2,
              borderRadius: 2,
              textAlign: 'center',
              width: '40%',
            }}
          >
            <h2 style={{textTransform:"none",fontFamily:"Barlow"}}>Email Form</h2>
            <form onSubmit={formik.handleSubmit}>
              <div>
                <TextField
                  label="To"
                  type="text"
                  fullWidth
                  value={formik.values.to}
                  onChange={(e) => formik.setFieldValue('to', e.target.value)}
                  onBlur={formik.handleBlur}
                  name="to"
                  error={formik.touched.to && Boolean(formik.errors.to)}
                  helperText={formik.touched.to && formik.errors.to}
                  sx={{ mb: 2 ,fontFamily:"Barlow"}}
                />
              </div>
              <div>
                <TextField
                  label="CC"
                  type="text"
                  fullWidth
                  value={formik.values.cc}
                  onChange={(e) => formik.setFieldValue('cc', e.target.value)}
                  onBlur={formik.handleBlur}
                  name="cc"
                  error={formik.touched.cc && Boolean(formik.errors.cc)}
                  helperText={formik.touched.cc && formik.errors.cc}
                  sx={{ mb: 2,fontFamily:"Barlow" }}
                />
              </div>
              {/* <div>
                <TextField
                  label="PDF S3 ID"
                  type="text"
                  fullWidth
                  value={pdfS3Id}
                  disabled
                  style={{ cursor: 'not-allowed' }}
                />
              </div> */}
              <Box display={'flex'} justifyContent="space-between" mt={1}>
                <Button 
                  sx={{textTransform:"none",fontFamily:"Barlow"}}
                  variant="contained" type="button" onClick={handleClose}>
                  Back
                </Button>
                <Button 
                  sx={{textTransform:"none",fontFamily:"Barlow"}}
                  variant="contained" type="submit">
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
    );
  };
  
  export default EmailForm;