import { unitOfTime } from "moment";
import { PUSH_NOTIFICATION } from "../constants/actions";
import COMMON from "../constants/common";
import { ACCESS_TOEKN, LOADER,VERSION_DATA,IS_EXISTING_CUSTOMER,IS_TOOLS_RUN, IS_SCORE_CALCULATED, SET_IS_LOADING_TOOLS, SET_SHOW_VERSIONS, SET_VERSION_LOADER} from "./actions";
import initialTemplateJson from '../../src/screen/initial.json'
// Reducer

interface TimeValuesProps {
  timeKey:string | null,
  timeDelta:number | string | null,
  format:unitOfTime.DurationConstructor | null | string
}


export interface NotificationProps {
  isOpen: boolean;
  type?: "error" | "info" | "success" | "warning";
  message?: string;
  handleClose?: () => void;
  versionData?:any;
  isExistingCustomer?:boolean;
  isToolsRun?:any;
  isScoreDone?:boolean;
  versionLoader?:any;
  isLoadingTools?:boolean;
  showDisplayVersions?:boolean
  
 
}
const initialState = {
  isLoading: false,
  notification: {},
  isAnonymous: true,
  access_token: '',
  versionData:{...initialTemplateJson},
  isExistingCustomer: false,
  isToolsRun:{
      toolsButton:true,
      menus:true
  },
  isScoreDone:false,
  versionLoader:"",
  isLoadingTools:false,
  showDisplayVersions:false
};


export const loadingActionReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADER:
      return {
        ...state,
        isLoading: action.payload,
      };

    case PUSH_NOTIFICATION:
      return { ...state, notification: action.payload };

    case COMMON.ANONYMOUS:
      return {
        ...state,
        isAnonymous: action.payload,
      };

    case ACCESS_TOEKN:
      return {
        ...state,
        access_token: action.payload
      };

    case VERSION_DATA:
      return {
        ...state,
        versionData: action.payload
      };

    case IS_EXISTING_CUSTOMER:
      return {
        ...state,
        isExistingCustomer: action.payload
      };

    case IS_TOOLS_RUN:
      return {
        ...state,
        isToolsRun: action.payload
      };
    case IS_SCORE_CALCULATED:
      return {
        ...state,
        isScoreDone: action.payload
      };
    case SET_VERSION_LOADER:
        return {
          ...state,
          versionLoader: action.payload,
        };
    case SET_IS_LOADING_TOOLS:
      return {
        ...state,
        isLoadingTools: action.payload,
      }
    case SET_SHOW_VERSIONS:
      return {
        ...state,
        showDisplayVersions: action.payload,
      }
   
    default:
      return state;
  }
};
