// HomePage.js
import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setIsExistingCustomer, setVersionData } from '../redux/actions';
import initialData from './initial.json'

const HomePage = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const handleCustomerClick = (customer) => {

    if(customer === "newCustomer"){
      dispatch(setIsExistingCustomer(false))
      dispatch(setVersionData(initialData))

      navigate('/new-customer');
    }else{
      dispatch(setIsExistingCustomer(true))
      navigate('/existing-customer');
    }    
  };


  return (
    <Container maxWidth="sm">
      <Box my={4} display={"flex"} justifyContent={"center"} textAlign="center">
        <Typography
          pl={2} pr={2} 
          sx={{
            fontFamily:"Barlow",
            background: "linear-gradient(to right, #FF6B6B, #6B6BFF)",
           color:"white"
          }}
           variant="h4">ERA</Typography>
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        size="large"
        sx={{textTransform:"none",fontFamily:"Barlow"}}
        onClick={()=>handleCustomerClick("newCustomer")}
      >
        New Customer
      </Button>

      <Box mt={2}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          size="large"
          sx={{textTransform:"none",fontFamily:"Barlow"}}
          onClick={()=>handleCustomerClick("existing")}
          >
          Existing Customer
        </Button>
      </Box>
    </Container>
  );
};

export default HomePage;
