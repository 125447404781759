import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  TextField,
  Button,
  MenuItem,
  FormControl,
  Select,
  Box,
  IconButton,
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DynamicTextareaFields from './DynamicTextareaFields';
import { GET_DATA_BY_ID, SAVE_USER_DATA, UPDATE_TEMPLATE } from '../constants/endpoints';
import { request } from '../services/request';
import { useDispatch, useSelector } from 'react-redux';
import CONSTANTS from '../constants/constants';
import { pushNotification, setIsToolsRun, setShowDisplayVersions, setVersionData } from '../redux/actions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CustomerDetailsForm from './CustomerDetailsForm';
import { handleGoToExistingCustomer, handleRunTools, onSubmitFormData, onSubmitUserData } from './apis';
import { useNavigate } from 'react-router-dom';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import PreLoader from '../components/common/preLoader';

interface FormValues {
  [key: string]: string;
}

const DisplayVersions: React.FC<{ selectedItem: string;}> = ({
  selectedItem,
}) => {
  const isCustomerDetails = selectedItem === 'customer_details';

  const { isToolsRun,versionData } = useSelector((state: any) => ({ 
          isToolsRun: state.isToolsRun,
    versionData:state.versionData}));
  const [savedData, setSavedData] = useState<any>(null); // New state for saved data
  const [patchRhythmCount, setPatchRhythmCount] = useState(1); // Initialize count to 1
  const [selectedOption, setSelectedOption] = useState("");


  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    // Set default value for selectedOption when the component mounts
    setSelectedOption(Object.keys(versionData.data[selectedItem] || {})[0]);     

    }, [selectedItem]);

  const fieldKeys = isCustomerDetails
    ? Object.keys(versionData[selectedItem] || {}).filter((key) => key !== 'ip_address')
    : [];

  const validationSchema = Yup.object().shape(
    fieldKeys.reduce((schema, key) => {
      schema[key] = Yup.string().required(`${key.charAt(0).toUpperCase() + key.slice(1)} is required`);
      return schema;
    }, {})
  );

  const formik = useFormik({
    initialValues: {
      ...versionData,
      ...fieldKeys.reduce((values, key) => {
        values[key] = key === 'date' ? new Date().toISOString().split('T')[0].split('-').reverse().join('-') : versionData[selectedItem]?.[key] || '';
        return values;
      }, {} as FormValues),
    },
    validationSchema,
    onSubmit: async () => {

      onSubmitFormData(dispatch,formik,versionData)

    },
  });


  const handleSaveButtonClick = async () => {

    const userData = {
      date: formik.values['date'],
      name: formik.values['name'],
      url: formik.values['url'],
      version: formik.values['version'],
      customer_id: formik.values['id']['customer_id'],
    };

    // Update the saved data state
    const dataSaved = {
      customer_details: { ...userData },
      id: { ...formik.values.id },
      data: { ...formik.values.data },
      score: { ...formik.values.score },
    };
    setSavedData(dataSaved);

    console.log('saved data ', savedData);
  };

  const handleTextareaChange = (field: string, value: string) => {
    formik.setFieldValue(`data.${selectedItem}.${selectedOption}.${field}`, value);
  };
  

  const handleAddPatchRhythm = () => {

    const newKey = `${patchRhythmCount + 1}_insecure_software_versions_and_unpatched_versions`;

    // Create a new copy of the data and update the selectedItem directly
    const updatedData = {
      ...versionData,
      data: {
        ...versionData.data,
        [selectedItem]: {
          ...versionData.data[selectedItem],
          [newKey]: {
            observation: 'N/A',
            why_is_it_an_issue: 'Not an Issue',
            mitigations: 'No mitigations needed',
            priority: 'Informative',
          },
        },
      },
    };

    // Update the data state
   dispatch(setVersionData(updatedData));
    formik.setValues({
      ...formik.values,
      data: {
        ...formik.values.data,
        [selectedItem]: {
          ...formik.values.data[selectedItem],
          [newKey]: {
            observation: '',
            why_is_it_an_issue: 'Not an Issue',
            mitigations: 'No mitigations needed',
            priority: 'Informative',
          },
        },
      },
    });

    setPatchRhythmCount(patchRhythmCount + 1);

    dispatch(
      pushNotification({
        isOpen: true,
        message: "Added Extra Menu Item",
        type: CONSTANTS.SUCCESS,
      })
    );
  };

  if(versionData.customer_details.name === undefined){
    <Box  sx={{fontFamily:"Barlow"}}>Version data not found</Box>
  }

  return (
    <Container>
      {(!isCustomerDetails) && 
          selectedItem === "dns_and_domain" && 
          <Box display={"flex"} flexDirection="column" justifyContent="center">
            <IconButton 
                title='Go back'
                sx={{
                    fontFamily:"Barlow",
                    textTransform: "none",
                    alignSelf:"flex-start" }} 
                  onClick={() => {
              dispatch(setShowDisplayVersions(false));

            } }>
              <ArrowCircleLeftIcon sx={{fontSize:"2rem"}} color='primary'/>
            </IconButton>
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="h6"
                 sx={{fontFamily:"Barlow"}}
                  textTransform={"capitalize"}>
                  <span style={{ color: 'red' }}>Company Name: </span>
                  {versionData.customer_details.name}
                </Typography>
                <Typography  sx={{fontFamily:"Barlow"}} variant="h6">
                  <span style={{ color: 'red' }}>Date: </span>
                  {versionData.customer_details.date}
                </Typography>
                <Typography  sx={{fontFamily:"Barlow"}} variant="h6">
                  <span style={{ color: 'red' }}>Version: </span>
                  {versionData.customer_details.version}
                </Typography>
              </Box>
            </>
            </Box>}

      <form onSubmit={formik.handleSubmit}>
        {isCustomerDetails ?
        <div>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
          <Typography  sx={{fontFamily:"Barlow"}} variant='h4'>New Customer</Typography>
          <Button 
              onClick={()=>handleGoToExistingCustomer(dispatch,navigate)}
              sx={{width:"15%",textTransform:"none" ,fontFamily:"Barlow"}} 
              variant='contained'>Existing Customer
          </Button>
        </Box>
        <CustomerDetailsForm fieldKeys={fieldKeys} formik={formik} /> </div> : (
          <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl fullWidth variant="outlined" margin="normal">
                <Select
                  id="selectedOption"
                  name="selectedOption"
                  label="Select Option"
                  value={selectedOption ? selectedOption : Object.keys(versionData.data[selectedItem] || {})[0]}
                  onChange={(e) => setSelectedOption(e.target.value as string)}
                  sx={{fontFamily:"Barlow"}}
                >
                  {Object.keys(versionData.data[selectedItem] || {}).map((menuItem) => (
                    <MenuItem key={menuItem} value={menuItem}>
                      {menuItem.replace(/_/g, ' ')}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {selectedItem.includes('patch_rhythm') && (
                <Button onClick={handleAddPatchRhythm}>
                  <AddCircleOutlineIcon />
                </Button>
              )}
            </Box>
            {versionData.data[selectedItem] && selectedOption && versionData.data[selectedItem][selectedOption] &&
            <DynamicTextareaFields
              fields={Object.keys(versionData.data[selectedItem][selectedOption])}
              formik={formik}
              selectedItem={selectedItem}
              onSaveClick={handleSaveButtonClick}
              onTextareaChange={handleTextareaChange}
              previewData ={versionData}
              selectedOption={selectedOption}/>

            }
              
          </div>
        )}
        {isCustomerDetails && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Button
              disabled={!isToolsRun.toolsButton}
              onClick={() => onSubmitUserData(dispatch,formik.values)}
              variant="contained"
              color="primary"
              sx={{textTransform:"none",fontFamily:"Barlow",marginTop: '1rem' }}
            >
              Submit
            </Button>
            <PreLoader/>
            <Button
              disabled={isToolsRun.toolsButton}
              variant="contained"
              color="primary"
              onClick={()=>{
                handleRunTools(dispatch,versionData)
              }}
              sx={{textTransform:"none",fontFamily:"Barlow",marginTop: '1rem' }}
            >
              Run Tools
            </Button>
          </Box>
        )}
      </form>
    </Container>
  );
};

export default DisplayVersions;
