import { List, ListItemButton, ListItemText, Container, Typography, Paper, Box, Button, Modal, Tooltip, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { GET_CUSTOMER_DETAILS,GET_VERSION_BY_ID } from '../constants/endpoints';
import {setLoader, setShowDisplayVersions } from '../redux/actions';
import { request } from '../services/request';
import { handleDeleteVersion, handleGoToNewCustomer, handleRunTools, handleVersionClick, onSubmitUserData, PDFPreview } from './apis';
import CustomerDetails from './Customer';
import CustomPopup from './CustomPopup';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailForm from './EmailForm';
import DeleteConfirmationDialog from './DeleteConfirmation';
import PreLoader from '../components/common/preLoader';


const disabledButtonStyle = {
  backgroundColor: '#69a6db !important',
  color: 'lightgrey !important',
  '&:hover': {
    backgroundColor: 'red !important',
  },
  textTransform:"none",
  fontFamily:"Barlow"
};

const ExistingCustomer = () => {
  const [activeCustomer, setActiveCustomer] = useState<{ customerId: string; name: string }>({
    customerId:"",
    name: "",
  });
  const [customers, setCustomers] = useState<any[]>([]);
  const [versions,setVersions] = useState<any[]>([])
  const [customerState, setCustomerState] = useState<{ data: any; isNewVersion: boolean }>({
    data: null,
    isNewVersion: false,
  });
  const { versionData } = useSelector((state: any) => ({ versionData: state.versionData }));
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [emailDetails, setEmailDetails] = useState<any>(); // Set the initial value based on your logic
  // const [showDisplayVersions, setShowDisplayVersions] = useState(false);

  const { showDisplayVersions,versionLoader } = useSelector((state: any) => ({ 
    showDisplayVersions: state.showDisplayVersions,versionLoader:state.versionLoader }));

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(()=>{
    getCustomerDetails()
  },[])


  useEffect(()=>{

    if(activeCustomer.customerId){
       handlegetCustomerVersions()
    }

  },[activeCustomer,customerState])


  const [isEmailFormOpen, setIsEmailFormOpen] = useState(false);
  const [versionToDelete,setVersionToDelete] = useState("")


  const handleOpenDeleteConfirmation = (item) => {
    setVersionToDelete(item.version_id)
    setDeleteConfirmationOpen(true);
  };

  const handleCloseDeleteConfirmation = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmation = async () => {
    handleDeleteVersion(dispatch,versionToDelete,handlegetCustomerVersions)
    setDeleteConfirmationOpen(false);
  };

  const openEmailForm = (item) => {
    setEmailDetails({
      attachments:item.pdf_id,
      name:item.name,
      date:item.date
    })
    setIsEmailFormOpen(true);
  };

  const closeEmailForm = () => {
    setIsEmailFormOpen(false);
  };

  const handleCustomerClick = (customer) =>{
    setActiveCustomer({
      customerId:customer.customer_id,
      name:customer.name
    })
  }

  const handlegetCustomerVersions = async () => {
    const res:any = await request
      .post(`${process.env.REACT_APP_ERA_GATEWAY_URL}${GET_VERSION_BY_ID}`,
      {"customer_id":activeCustomer.customerId}
      )

      if(res?.success){
        setVersions(res?.data)
      }else{
        getCustomerDetails()
      }
      
  };
   
  const handleCreateNewVersion = () =>{

    const maxVersion = versions?.reduce((max, obj) => Math.max(max, obj.version), 0);

    let nextVersionNumber = maxVersion + 1
     
    const customerData = {
      date:new Date().toISOString().split('T')[0].split('-').reverse().join('-'),
      name: versions[0].name,
      url: versions[0].url,
      version: nextVersionNumber,
      id:{
        customer_id: versions[0].customer_id,
      }
    };

    setCustomerState({
      data: customerData,
      isNewVersion: true,
    });


  }

  const handleCustomerWithNewVersion = () =>{
    onSubmitUserData(dispatch,customerState.data)
  }

  const getCustomerDetails = async () => {
    dispatch(setLoader(true))
      const res:any = await request.get(`${process.env.REACT_APP_ERA_GATEWAY_URL}${GET_CUSTOMER_DETAILS}`)
      dispatch(setLoader(false))
      setCustomers(res?.data)   
      setActiveCustomer({
        customerId:res?.data[0]?.customer_id,
        name: res?.data[0]?.name,
      })  
      
  }

 

  const handleGoHome = () =>{
    navigate("/")
  }

  
   if(showDisplayVersions){
      return <CustomerDetails/>
  }
  else if(customers?.length !== undefined && customers?.length === 0 || versions === undefined){
    return (
      (versions?.length !== 0 && customers?.length === 0)?
      <Box
        display={"flex"}
        flexDirection="column"
        justifyContent={"center"}
        alignItems="center">
            <h1  style={{textTransform:"none",fontFamily:"Barlow"}}>No Customers found</h1>
            <Button  sx={{textTransform:"none",fontFamily:"Barlow"}} onClick={handleGoHome} variant='contained'>Go Home</Button>
        </Box>:null
    )
  }
  return (
    
      <Box
      sx={{
          display: 'flex', 
          alignItems:"flex-start",
          justifyContent:"center",
      }}
      >
      {customerState.isNewVersion && customerState.data && (
        <CustomPopup
          open={customerState.isNewVersion}
          onClose={() => setCustomerState({ data: null, isNewVersion: false })}
          customerData={customerState.data}
          onSave={handleCustomerWithNewVersion}
          onRun={()=>{
            handleRunTools(dispatch,versionData)
            setShowDisplayVersions(true)
          }}
        />
      )}
      <EmailForm
          isOpen={isEmailFormOpen}
          handleClose={closeEmailForm}
          emailData={emailDetails}
        />
      <Box 
          sx={{
              display: 'flex', 
              flexDirection:"column", 
              alignItems:"flex-start",
              justifyContent:"flex-start",
              padding:"1rem",
              overflowY:"auto",
              height: '100vh' }}>
        {/* Sidebar with customer list */}
        <Typography variant="h4">Customers</Typography>

          <List sx={{ minWidth: '200px' }}>
              {customers && customers?.map((customer) => (
              <ListItemButton
                  key={customer?.customer_id}
                  onClick={() => handleCustomerClick(customer)}
                  sx={{
                  marginTop: "1rem",
                  fontWeight:"700 !important",
                  color: activeCustomer.customerId === customer.customer_id ? 'white' : 'blue',
                  backgroundColor:activeCustomer.customerId === customer.customer_id ? 'blue !important' : 'white',
                  '&:hover': {
                      color: 'white',
                      backgroundColor: 'lightblue !important',
                  },
                  }}
                  selected={activeCustomer.customerId === customer.customer_id}
              >
                  <ListItemText  sx={{fontFamily:"Barlow"}} primary={customer.name} />
              </ListItemButton>
              ))}
          </List>
      </Box>
        <Container sx={{display:"flex",flexDirection:"column",}}>
        {versions.length !== 0 &&
          <>
          <Button 
              onClick={()=>handleGoToNewCustomer(dispatch,navigate)}
              sx={{mt:3.6,alignSelf:"flex-end",textTransform:"none" ,fontFamily:"Barlow"}} 
              variant='contained'>New customer</Button>
            <Paper sx={{ maxHeight:'calc(90vh - 100px)',overflowY:"scroll",padding: '1rem', marginTop: '1rem' }}>
                {versions?.map((item: any, index) => {
                  return (
                    <Box
                      key={index}
                      display={"flex"}
                      justifyContent={"space-around"}
                      alignItems="center"
                      mt={2}
                    >
                    <Box display={"flex"} alignItems="center">
                      <Button 
                        sx={{textTransform:"none",fontFamily:"Barlow"}}
                        variant='contained' 
                        onClick={() => handleVersionClick(dispatch,item.version_id)}>
                          V{item.version}</Button>
                          {versionLoader ===item.version_id && <PreLoader/>}
                     </Box>
                      <Button variant='outlined' sx={{cursor:"default"}}>{item.date}</Button>
                    
                      <Button variant='contained' 
                          disabled={!item.pdf_id}
                          sx={item.pdf_id ? { textTransform:"none",fontFamily:"Barlow"} : disabledButtonStyle}
                        onClick={()=>openEmailForm(item)}>Email</Button>
                      
                      <Button 
                        variant='contained' 
                        disabled={!item.pdf_id}
                        title={!item.pdf_id?"disabled":"allowed"}
                        sx={item.pdf_id ? { textTransform:"none",fontFamily:"Barlow"} : disabledButtonStyle}
                        onClick={()=>PDFPreview(dispatch,item.pdf_id)}>Pdf Preview</Button>
                      <Button 
                          sx={{color:"white",backgroundColor:"red"}}
                          onClick={() => {
                              handleOpenDeleteConfirmation(item)
                            }}>
                        <DeleteIcon/>
                      </Button>
                    </Box>
                  );
                })}
            </Paper>
            <Button 
              onClick={handleCreateNewVersion}
              sx={{mt:2,alignSelf:"flex-end",textTransform:"none"}} 
              variant='contained'>create a new version</Button>
          </>
        }
        <DeleteConfirmationDialog
          open={deleteConfirmationOpen}
          onClose={handleCloseDeleteConfirmation}
          onConfirm={handleDeleteConfirmation}
        />
        </Container>
      </Box>
   
  );
};

export default ExistingCustomer;
