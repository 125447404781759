import React, { useEffect } from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemButton } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { setIsExistingCustomer } from '../redux/actions';
import CONSTANTS from '../constants/constants';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  activeMenuItem: {
    backgroundColor: theme.palette.action.selected,
  },
}));

const Sidebar = ({ data, onItemClick, selectedItem }) => {
  const classes = useStyles();

  const getButtonStyle = (item) => ({
    backgroundColor: selectedItem === item ? '#2196f3' : 'transparent',
  });

  const { isToolsRun } = useSelector((state: any) => ({ isToolsRun: state.isToolsRun}));


  const isExistingCustomer = sessionStorage.getItem("isExistingCustomer") === "true"?true:false

  const isDisabled = isExistingCustomer?false:isToolsRun.menus

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <List
        sx={{
          display: 'flex',
          pl: 2,
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
        }}
      >
        {!isExistingCustomer && (
          <ListItemButton
            key="customer_details"
            onClick={() => onItemClick('customer_details')}
            selected={selectedItem === 'customer_details'}
            sx={getButtonStyle('customer_details')}
          >
            <ListItemText  sx={{fontFamily:"Barlow"}} primary="Customer Details" />
          </ListItemButton>
        )}
        {Object.keys(data.data).map((menuItem) => (
          <ListItemButton
            key={menuItem}
            onClick={() => onItemClick(menuItem)}
            disabled={isDisabled}
            selected={selectedItem === menuItem}
            sx={getButtonStyle(menuItem)}
          >
            <ListItemText
              sx={{fontFamily:"Barlow"}}
              primary={menuItem.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())}
            />
          </ListItemButton>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;